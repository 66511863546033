import type { Breadcrumb as SentryBreadcrumb } from '@sentry/types';

import * as SentryReporter from 'js/src/libs/sentry';
import { getPlanDisplayName } from 'js/src/libs/server-utils';
import type { SERVER_TYPE } from 'js/src/typings/global';

export enum Project {
    FRONTEND,
    LUMINARY
}

type ContextData = Record<string, unknown>;
export type Context = {
    level?: 'error' | 'warning' | 'log';
    extra?: ContextData;
}


export type ReleaseStage = 'production' | 'staging' | 'development'

type User = {
    [key: string]: any;
    id?: string | number;
    email?: string;
    username?: string;
}

// Let's use sentry's breadcrumb type
type Breadcrumb = SentryBreadcrumb;


/**
 * A generic class for reporting errors to a backend service.
 * We should try to keep this generic and define our own types so that if we want to
 * swap or add another error reporting service, we can do so easily (without changing a lot of other code)
 */
export class ErrorReporter {
    static init(project: Project) {
        const releaseStage = getReleaseStage();
        SentryReporter.init(project, releaseStage);

        const userInfo = getUserInfo();
        userInfo && SentryReporter.setUser(userInfo);

        const featureFlags = getFeatureFlags();
        featureFlags.forEach(flag => {
            SentryReporter.setTag(`ff_${flag}`, true);
        });
        SentryReporter.setTag('plan', getPlanDisplayName());
    }

    static setUser(user: User) {
        SentryReporter.setUser(user);
    }

    static getUser(): User| undefined {
        return SentryReporter.getUser();
    }

    /**
     * Capture an error or a message to report.
     * It's up to the underlying error reporter to decide how to categorize & handle the error.
     */
    static capture(error: unknown, context?: Context) {
        SentryReporter.capture(error, context);
    }

    static addBreadcrumb(breadcrumb: Breadcrumb) {
        SentryReporter.addBreadcrumb(breadcrumb);
    }

    static setContext(name: string, context: ContextData) {
        SentryReporter.setContext(name, context);
    }

    /**
     * When using $.ajax, supply this as the callback value to the error option
     * if you'd like to report the failure
     */
    static captureAjaxFailure(this: JQueryAjaxSettings, xhr: JQuery.jqXHR) {
        const errCodes = [400, 401, 403, 500, 502, 524];
        if (!errCodes.includes(xhr.status)) {
            return;
        }
        if (this === undefined) {
            return;
        }

        SentryReporter.capture(`${this.type} ${this.url} resulted in a ${xhr.status} error`, {
            level: 'error',
            extra: {
                status: xhr.statusText,
                response: xhr.responseText,
            },
        });
    }
}
// expose this so luminary can also use it
window.ErrorReporter = ErrorReporter;

/**
 * A utility to create the impersonate link for the given email. Helpful for debugging.
 */
export function createImpersonateUrl(email: string) {
    const url = new URL(window.location.href);
    url.searchParams.set('__impersonate', email);
    return url.toString();
}


/**
 * SERVER can be undefined, but we assume it's not most of the time. For error reporting
 * we should properly handle the case where SERVER is undefined. Otherwise we won't
 * be notified of the error.
 */
function getServerData(): SERVER_TYPE | undefined {
    return window.SERVER;
}

function getReleaseStage(): ReleaseStage {
    const SERVER = getServerData();
    if (SERVER?.DEBUG) {
        return 'development';
    }
    else if (SERVER?.END_TO_END_TESTING_MODE) {
        return 'development';
    }
    return 'production';
}

/**
 * Gather the user info for the error context. If adding info here, we may want to
 * add similar data in the django/server side, so it's consistent across projects.
 */
function getUserInfo(): User | undefined {
    const SERVER = getServerData();
    if (!SERVER) {
        return;
    }
    return {
        id: SERVER.USER?.id,
        email: SERVER.USER?.email,
        username: SERVER.ANALYTICS?.username,
        plan: getPlanDisplayName(),
        role: SERVER.USER?.role,
        feature_flags: getFeatureFlags(),
        impersonate: createImpersonateUrl(SERVER.USER?.email || ''),
    };
}

function getFeatureFlags(): string[] {
    const SERVER = getServerData();
    return SERVER?.USER?.feature_flags || [];
}
